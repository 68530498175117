import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HsncodeService } from '../hsncode.service';

@Component({
  selector: 'app-hsncode-add',
  templateUrl: './hsncode-add.component.html',
  styleUrls: ['./hsncode-add.component.scss']
})
export class HsncodeAddComponent implements OnInit {
  HSNCodeForm: FormGroup;

  constructor(private hsncodeService:HsncodeService,
    private formBuilder: FormBuilder) {
      this.HSNCodeForm = formBuilder.group({
        HSNCode:['',Validators.required],
        HSNCode_Desc:[''],
        sgst_rate:['',Validators.required],
        cgst_rate:['',Validators.required],
        igst_rate:['',Validators.required],
      })
     }

  ngOnInit(): void {
  }

  saveHSNCode() {
    const hsncodeData=this.HSNCodeForm.value;
    this.hsncodeService.createHSNCode(hsncodeData).subscribe(data => {
      this.HSNCodeForm.reset();
    })
  }

}
