import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private httpClient: HttpClient) {}

  login(users) {
    return this.httpClient.post(environment.apiURL + 'login', users);
  }

  getuser(userid) {
    return this.httpClient.get(environment.apiURL + 'getuser/' + userid);
  }

  logout(users) {
    return this.httpClient.post(environment.apiURL + 'logout', users);
  }
  register(user) {
    return this.httpClient.post(environment.apiURL + 'register', user);
  }
}
