import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoaderService } from 'src/app/loader/loader.service';
import { DiscountsubtypeService } from '../discountsubtype.service';

@Component({
  selector: 'app-select-discountsubtype',
  templateUrl: './select-discountsubtype.component.html',
  styleUrls: ['./select-discountsubtype.component.scss']
})
export class SelectDiscountsubtypeComponent implements OnInit {

  discountSubTypes: any;
  selectedDiscountSubtypeId;
  constructor(private discountSubtypeService : DiscountsubtypeService,
              public loaderService: LoaderService,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private dialogRef: MatDialogRef<SelectDiscountsubtypeComponent>) {
   this.discountSubtypeService.getDiscountSubtypes().subscribe(data=> {
      this.discountSubTypes = data;    
    })
   }
   getValue(subtype) {
      //console.log(subtype);
      if(subtype) {}
      this.dialogRef.close({ data: subtype })
   }
  ngOnInit(): void {
   
  }

}
