import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class sendOTPemailService {
  constructor(private httpClient: HttpClient) {}

  senEmail(emaildata) {
    return this.httpClient.post(environment.apiURL + 'email', emaildata);
  }
}
