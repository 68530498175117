import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DiscountsubtypeService {
  HttpUploadOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
    }),
  };
  constructor(private httpClient: HttpClient) { }
  getDiscountSubtypes() {
    return this.httpClient.get(environment.apiURL + 'discountsubtype');
  }
  createDiscountSubtype(DiscountSubtypeData) {
    return this.httpClient.post( environment.apiURL + 'discountsubtype', DiscountSubtypeData, this.HttpUploadOptions );
 }
}
