<p *ngIf="loaderService.isloading | async"></p>
<h1 *ngIf="discountSubTypes" mat-dialog-title>Select discount type</h1>
<nav *ngFor="let discSubType of discountSubTypes">  
    <a (click)="getValue(discSubType)" style="cursor: pointer; text-decoration:none;color:black">
        <div class="row">
            <div class="col-10">
                <b>{{discSubType.subtype}}</b> <br>
                {{discSubType.discount_type.type}}
            </div>
            <div class="col-2" style="margin:10px 0;"> <mat-icon>navigate_next</mat-icon> </div>
            
        </div>
    </a>  
</nav>
