
<form [formGroup]="HSNCodeForm" (ngSubmit)="saveHSNCode()" autocomplete="off">
  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field hideRequiredMarker=false class="example-full-width" appearance="outline">
          <mat-label>Enter HSN Code</mat-label>
          <input #name matInput maxlength="50" formControlName="HSNCode" placeholder="HSN Code" required>
          <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field hideRequiredMarker=false class="example-full-width" appearance="outline">
          <mat-label>Enter HSN Code Description</mat-label>
          <input #name matInput maxlength="50" formControlName="HSNCode_Desc" placeholder="HSN Code Desc" required>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field hideRequiredMarker=false class="example-full-width" appearance="outline">
          <mat-label>Enter SGST %</mat-label>
          <input class="example-right-align" type="number" #name matInput maxlength="50" formControlName="sgst_rate" placeholder="SGST %" required>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field hideRequiredMarker=false class="example-full-width" appearance="outline">
          <mat-label>Enter CGST %</mat-label>
          <input class="example-right-align" type="number" #name matInput maxlength="50" formControlName="cgst_rate" placeholder="CGST %" required>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <mat-form-field hideRequiredMarker=false class="example-full-width" appearance="outline">
          <mat-label>Enter IGST %</mat-label>
          <input class="example-right-align" type="number" #name matInput maxlength="50" formControlName="igst_rate" placeholder="IGST %" required>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>
        <button mat-raised-button color="warn"  mat-dialog-close="true">Close</button> &nbsp;
        <button type="submit" [disabled]="!HSNCodeForm.valid" mat-dialog-close="true" mat-raised-button color="primary">
            Submit
        </button>
      </td>
    </tr>
  </table>
</form>


