import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HsncodeService {
  HttpUploadOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
    }),
  };

  constructor(private httpClient: HttpClient) {}

  getHSNCodes() {
    return this.httpClient.get(environment.apiURL + 'hsncode');
  }

  getHSNCodeswithGroup(HSNCodeId) {
    return this.httpClient.get(
      environment.apiURL + 'hsncode/indexwithgroup/' + HSNCodeId
    );
  }

  getHSNCode(HSNCodeId) {
    return this.httpClient.get(environment.apiURL + 'hsncode/' + HSNCodeId);
  }

  createHSNCode(HSNCodeData) {
     return this.httpClient.post( environment.apiURL + 'hsncode', HSNCodeData, this.HttpUploadOptions );
  }
  deleteHSNCode(HSNCodeId) {
    return this.httpClient.delete(environment.apiURL + 'hsncode/' + HSNCodeId);
  }
}
